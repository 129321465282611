@import url('https://fonts.googleapis.com/css2?family=SUSE:wght@100..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Azeret+Mono:ital,wght@0,100..900;1,100..900&family=SUSE:wght@100..800&display=swap');

*{
  background-color: #212121;
  color: white;
  font-family: "Azeret Mono", monospace;

}
details summary{
  font-size: large;
  color: #FFC107;
}

details p{
  font-size: medium;
}


button{
  /* width: 35%; */
}
input{
  /* width: 200px; */
  background-color: aqua;

}
.navbar-collapse.in {
  display: block !important;
}
.navbar-nav .nav-link.active {
  color: #ff0000 !important; /* Set your desired active link color */
}

.navbar-nav .nav-link {
  cursor: pointer;
  color: #f7f7f7 !important; /* Set your desired active link color */
}
@media   screen   and (max-width:768px) {


  details summary{
    font-size: small;
  }
  
  details p{
   
    font-size: 70%;
  }
  
  
}
#auth{
  max-width:  400px;
}


.prompt-box::placeholder{
  color: white;
  opacity: 0.5;
}

nav p{user-select: none;}
.MainLogo{
  user-select: none;
  cursor: pointer;
  display: flex;
}


.animate-cooking{

animation-name: cooking;
animation-duration: 1s;
animation-iteration-count: infinite;
animation-fill-mode: forwards;

}

@keyframes cooking{
  0%{
transform: rotateZ(0deg);

  }
  50%{
    transform: rotateZ(5deg);

  }
  100%{
    transform: rotateZ(-5deg);

  }

}
